<template>
    <div id="proile-settings">
        <v-card class="mb-7">
            <v-card-text class="pa-5 border-bottom">
                <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Настройки доступа</h3>
            </v-card-text>
            <v-card-text>

                <v-text-field
                    v-model="old_password"
                    label="Старый пароль"
                    filled
                    background-color="transparent"
                    type="password"
                    ></v-text-field>

                <v-text-field
                    v-model="password"
                    label="Новый пароль"
                    type="password"
                    filled
                    background-color="transparent"
                    ></v-text-field>
                <v-text-field
                    v-model="password2"
                    label="Подтвердите новый пароль"
                     type="password"
                    filled
                    background-color="transparent"
                    ></v-text-field>
                <v-btn color="success" @click="change_password()" class="text-capitalize mr-2 mb-4">Сохранить</v-btn>
                <v-alert             
                    :value="alert"
                    color="success"
                    type="success"

                    dense
                    dark
                    transition="scale-transition"
                    >
                    Сохранено!
                </v-alert>
            </v-card-text>

        </v-card>

    </div>
</template>

<script>

  export default {
    name: 'profile-settings',
    components: { 

    },
    data () {
      return {
          alert:false,
        phone: '',
        email: '',
        password: '',
        password2: '',
        old_password: '',
        new_phone: '',
        secret_key: '',
        key_sent: false,
        errors: {}
      }
    },
    methods: {

      change_password () {
        if(this.password !== this.password2)
        {
          alert('Введенные пароли не совпадают')
          return false
        }
        this.$http.get('user/change-password',{
          params: {
            old_password: this.old_password,
            new_password: this.password
          }
        })
        .then(response => {
          alert(response.data)
        }).catch(e => {
          alert(e.response.data)
        })        
      },      
      change_phone () {
        this.$http.get('user/set-phone',{
          params: {
            key: this.secret_key,
            phone: this.new_phone
          }
        })
        .then(response => {
          this.phone = this.new_phone
            this.$auth.user().license.push(response.data)
          this.$refs.phone_change.hide()
        }).catch(e => {
          alert(e.response.data)
        })        
      },
      send_key () {
        this.$http.get('user/get-secret',{
          params: {
            phone: this.new_phone
          }
        })
        .then(response => {
          alert(response.data)
          this.key_sent = true
        })
      },
      fetchData () {
        this.$http.get('user/security')
        .then(response => {
          this.phone = response.data.phone
          this.email = response.data.email
          // JSON responses are automatically parsed.
          // this.errors = response.data.errors
          //           console.log(this.errors)
        })
      }
    },
    created () {
      this.fetchData()
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
